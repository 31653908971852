<template>
  <div class="container">
    <div class="columns" style="flex-direction: column; align-items: center;">
      <div class="column block">
        <section class="section">
          <div class="columns" style="flex-direction: column; align-items: center;">
            <b-message type="is-warning" has-icon>
              <div class="columns" style="flex-direction: column; align-items: center">
                <div class="column">
                  <h3 class="title">Area protetta</h3>
                  <br />
                  <h4
                    class="subtitle is-size-6"
                  >I dati dell'applicazione sono protetti da password. E' necessario re-inserire la password per continuare.</h4>
                  <br />
                </div>

                <div class="column">
                  <form @submit.prevent="sblocca">
                    <b-field label="Password:" horizontal grouped>
                      <b-input type="password" v-model="password" password-reveal></b-input>
                    </b-field>
                  </form>
                </div>

                <div class="column">
                  <b-button
                    type="is-primary"
                    size="is-large"
                    @click="sblocca"
                    :disabled="password == ''"
                  >Sblocca</b-button>
                </div>
              </div>
            </b-message>
          </div>
        </section>
      </div>
      <Adsense
        class="column comeStoADS"
        data-ad-client="ca-pub-8018925037718321"
        data-ad-slot="6891980674"
        data-ad-format="auto"
        :data-full-width-responsive="true"
      ></Adsense>
    </div>
  </div>
</template>

<script>
export default {
  name: "askPassword",
  data: function() {
    return {
      password: ""
    };
  },
  methods: {
    sblocca() {
      let isCorrectPasswordPromise = this.$store.dispatch(
        "checkEncryptionKey",
        this.password
      );
      isCorrectPasswordPromise.then(correct => {
        if (correct) {
          this.$store.dispatch("setPassword", this.password);
          this.$router.push("/app");
        } else {
          this.password = "";
          this.$buefy.toast.open({
            duration: 2500,
            message: "Password errata",
            position: "is-bottom",
            type: "is-dark"
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.comeStoADS {
  text-align: center;
  margin-bottom: 20px;
  width: 70%;
}
</style>